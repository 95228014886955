import { InstrumentType } from '@zero/shared/util-models';

export const ALLOWED_ONBOARDING_CHARS =
  ' !"#$%&\'()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[\\]^_`abcdefghijklmnopqrstuvwxyz{|}~¡¢£¤¥¦§¨©ª«¬®¯°±²³´µ¶·¸¹º»¼½¾¿ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõö÷øùúûüýþÿĀāĂăĄąĆćĊċČčĎďĐđĒēĔĕĖėĘęĚěĞğĠġĢģĦħĨĩĪīĬĭĮįİıĴĵĶķĸĹĺĻļĽľĿŀŁłŃńŅņŇňŉŊŋŌōŎŏŐőŒœŔŕŖŗŘřŚśŞşŠšŢţŤťŦŧŨũŪūŮůŰűŲųŴŵŶŷŸŹźŻżŽžƏƠơƯưƷǍǎǏǐǑǒǓǔǞǟǤǥǦǧǨǩǪǫǬǭǮǯǰǴǵǺǻǼǽǾǿȘșȚțȞȟȪȫȮȯȰȱȲȳḂḃḊḋḐḑḞḟḠḡḤḥḦḧḰḱṀṁṄṅṖṗṠṡṢṣṪṫẀẁẂẃẄẅẌẍẎẏẐẑẒẓẞẠạẢảẤấẦầẪẫẬẮắẰằẲẳẴẵẶặẸẹẺẻẼẽẾếỀềỄễỆệỈỉỊịỌọỎỏỐốỒồỖỗỘộỚớỜờỤụỦủỨứỪừỬửỮữỰựỲỳỴỵỶỷỸỹ\n';
export const defaultQuoteFormat = '1.2-6';
export const defaultQuantityFormat = '1.0-3';
export interface FamousIndication {
  name: string;
  isin: string;
  instrumentType: InstrumentType;
  isOverviewInstrument?: boolean;
  order?: number;
  priceScale?: number;
}
export interface IndicationQuoteMapping {
  isin: string;
  quoteIsin: string;
}
export const lowTradingThreshold = 500.0;
export const minimumQuantitySurcharge = 1.0;
export const logoutNotificationThreshold = 300 * 1000;
export const durationUntilLogout = 24 * 60 * 60 * 1000;
export const minimumOrderValue = 0.0;
export const pollingFallbackInterval = 10000;
export const ACCOUNT_OPEN_REFERENCE_ID_PARAM_NAME = 'aorefid';
export const MAX_ORDER_VALIDITY_DAYS = 360;
export const DEFAULT_TRADING_HOURS_START_TIME = '08:00';
export const DEFAULT_TRADING_HOURS_STOP_TIME = '22:00';
export const POLLING_FOR_AUTHORIZATION_INTERVAL_MS = 2000;
export const MAX_UPLOAD_FILE_SIZE = 1024 * 1024 * 9;
export const MIN_SAVINGPLAN_AMOUNT = 1;
export const MAINTENANCE_MODE_STATUSCODE = 580;
export const STATS_DIVIDENDS_FORECAST_YEARS = 5;
export const INDICATION_QUOTE_MAPPINGS: IndicationQuoteMapping[] = [
  {
    //DAX
    isin: 'DE0008469008',
    quoteIsin: 'DE000HR3Q3M1',
  },
  {
    //TexDAX
    isin: 'DE0007203275',
    quoteIsin: 'DE000HR5EJ38',
  },
  {
    //MDAX
    isin: 'DE0008467416',
    quoteIsin: 'DE000HR4S387',
  },
  {
    //Euro Stoxx
    isin: 'EU0009658145',
    quoteIsin: 'DE000HR4S346',
  },
  {
    //Dow Jones
    isin: 'US2605661048',
    quoteIsin: 'DE000HB02P01',
  },
  {
    //NASDAQ
    isin: 'US6311011026',
    quoteIsin: 'DE000HB02NZ4',
  },
  {
    //S&P 500
    isin: 'US78378X1072',
    quoteIsin: 'DE000HB02P19',
  },
];
