export enum IsoCountries {
  DE = 'Deutschland',
  AT = 'Österreich',
  CH = 'Schweiz',

  AD = 'Andorra',
  AE = 'Vereinigte Arabische Emirate',
  AF = 'Afghanistan',
  AG = 'Antigua und Barbuda',
  AI = 'Anguilla',
  AL = 'Albanien',
  AM = 'Armenien',
  AO = 'Angola',
  AQ = 'Antarktis',
  AR = 'Argentinien',
  AS = 'Amerikanisch-Samoa',
  AU = 'Australien',
  AW = 'Aruba',
  AX = 'Ålandinseln',
  AZ = 'Aserbaidschan',
  BA = 'Bosnien und Herzegowina',
  BB = 'Barbados',
  BD = 'Bangladesch',
  BE = 'Belgien',
  BF = 'Burkina Faso',
  BG = 'Bulgarien',
  BH = 'Bahrain',
  BI = 'Burundi',
  BJ = 'Benin',
  BL = 'St. Barthélemy',
  BM = 'Bermuda',
  BN = 'Brunei Darussalam',
  BO = 'Bolivien',
  BQ = 'Bonaire, Sint Eustatius und Saba',
  BR = 'Brasilien',
  BS = 'Bahamas',
  BT = 'Bhutan',
  BV = 'Bouvetinsel',
  BW = 'Botsuana',
  BY = 'Belarus',
  BZ = 'Belize',
  CA = 'Kanada',
  CC = 'Kokosinseln',
  CD = 'Kongo-Kinshasa',
  CF = 'Zentralafrikanische Republik',
  CG = 'Kongo-Brazzaville',
  CI = 'Côte d’Ivoire',
  CK = 'Cookinseln',
  CL = 'Chile',
  CM = 'Kamerun',
  CN = 'China',
  CO = 'Kolumbien',
  CR = 'Costa Rica',
  CU = 'Kuba',
  CV = 'Kap Verde',
  CW = 'Curaçao',
  CX = 'Weihnachtsinsel',
  CY = 'Zypern',
  CZ = 'Tschechische Republik',
  DJ = 'Dschibuti',
  DK = 'Dänemark',
  DM = 'Dominica',
  DO = 'Dominikanische Republik',
  DZ = 'Algerien',
  EC = 'Ecuador',
  EE = 'Estland',
  EG = 'Ägypten',
  EH = 'Westsahara',
  ER = 'Eritrea',
  ES = 'Spanien',
  ET = 'Äthiopien',
  FI = 'Finnland',
  FJ = 'Fidschi',
  FK = 'Falklandinseln',
  FM = 'Mikronesien',
  FO = 'Färöer',
  FR = 'Frankreich',
  GA = 'Gabun',
  GB = 'Vereinigtes Königreich',
  GD = 'Grenada',
  GE = 'Georgien',
  GF = 'Französisch-Guayana',
  GG = 'Guernsey',
  GH = 'Ghana',
  GI = 'Gibraltar',
  GL = 'Grönland',
  GM = 'Gambia',
  GN = 'Guinea',
  GP = 'Guadeloupe',
  GQ = 'Äquatorialguinea',
  GR = 'Griechenland',
  GS = 'Südgeorgien und die Südlichen Sandwichinseln',
  GT = 'Guatemala',
  GU = 'Guam',
  GW = 'Guinea-Bissau',
  GY = 'Guyana',
  HK = 'Sonderverwaltungszone Hongkong',
  HM = 'Heard und McDonaldinseln',
  HN = 'Honduras',
  HR = 'Kroatien',
  HT = 'Haiti',
  HU = 'Ungarn',
  ID = 'Indonesien',
  IE = 'Irland',
  IL = 'Israel',
  IM = 'Isle of Man',
  IN = 'Indien',
  IO = 'Britisches Territorium im Indischen Ozean',
  IQ = 'Irak',
  IR = 'Iran',
  IS = 'Island',
  IT = 'Italien',
  JE = 'Jersey',
  JM = 'Jamaika',
  JO = 'Jordanien',
  JP = 'Japan',
  KE = 'Kenia',
  KG = 'Kirgisistan',
  KH = 'Kambodscha',
  KI = 'Kiribati',
  KM = 'Komoren',
  KN = 'St. Kitts und Nevis',
  KO = 'Kosovo',
  KP = 'Nordkorea',
  KR = 'Südkorea',
  KW = 'Kuwait',
  KY = 'Kaimaninseln',
  KZ = 'Kasachstan',
  LA = 'Laos',
  LB = 'Libanon',
  LC = 'St. Lucia',
  LI = 'Liechtenstein',
  LK = 'Sri Lanka',
  LR = 'Liberia',
  LS = 'Lesotho',
  LT = 'Litauen',
  LU = 'Luxemburg',
  LV = 'Lettland',
  LY = 'Libyen',
  MA = 'Marokko',
  MC = 'Monaco',
  MD = 'Republik Moldau',
  ME = 'Montenegro',
  MF = 'St. Martin',
  MG = 'Madagaskar',
  MH = 'Marshallinseln',
  MK = 'Mazedonien',
  ML = 'Mali',
  MM = 'Myanmar',
  MN = 'Mongolei',
  MO = 'Sonderverwaltungsregion Macau',
  MP = 'Nördliche Marianen',
  MQ = 'Martinique',
  MR = 'Mauretanien',
  MS = 'Montserrat',
  MT = 'Malta',
  MU = 'Mauritius',
  MV = 'Malediven',
  MW = 'Malawi',
  MX = 'Mexiko',
  MY = 'Malaysia',
  MZ = 'Mosambik',
  NA = 'Namibia',
  NC = 'Neukaledonien',
  NE = 'Niger',
  NF = 'Norfolkinsel',
  NG = 'Nigeria',
  NI = 'Nicaragua',
  NL = 'Niederlande',
  NO = 'Norwegen',
  NP = 'Nepal',
  NR = 'Nauru',
  NU = 'Niue',
  NZ = 'Neuseeland',
  OM = 'Oman',
  PA = 'Panama',
  PE = 'Peru',
  PF = 'Französisch-Polynesien',
  PG = 'Papua-Neuguinea',
  PH = 'Philippinen',
  PK = 'Pakistan',
  PL = 'Polen',
  PM = 'St. Pierre und Miquelon',
  PN = 'Pitcairninseln',
  PR = 'Puerto Rico',
  PS = 'Palästinensische Autonomiegebiete',
  PT = 'Portugal',
  PW = 'Palau',
  PY = 'Paraguay',
  QA = 'Katar',
  RE = 'Réunion',
  RO = 'Rumänien',
  RS = 'Serbien',
  RU = 'Russland',
  RW = 'Ruanda',
  SA = 'Saudi-Arabien',
  SB = 'Salomonen',
  SC = 'Seychellen',
  SD = 'Sudan',
  SE = 'Schweden',
  SG = 'Singapur',
  SH = 'St. Helena',
  SI = 'Slowenien',
  SJ = 'Svalbard und Jan Mayen',
  SK = 'Slowakei',
  SL = 'Sierra Leone',
  SM = 'San Marino',
  SN = 'Senegal',
  SO = 'Somalia',
  SR = 'Suriname',
  SS = 'Südsudan',
  ST = 'São Tomé und Príncipe',
  SV = 'El Salvador',
  SX = 'Sint Maarten',
  SY = 'Syrien',
  SZ = 'Swasiland',
  TC = 'Turks- und Caicosinseln',
  TD = 'Tschad',
  TF = 'Französische Süd- und Antarktisgebiete',
  TG = 'Togo',
  TH = 'Thailand',
  TJ = 'Tadschikistan',
  TK = 'Tokelau',
  TL = 'Timor-Leste',
  TM = 'Turkmenistan',
  TN = 'Tunesien',
  TO = 'Tonga',
  TR = 'Türkei',
  TT = 'Trinidad und Tobago',
  TV = 'Tuvalu',
  TW = 'Taiwan',
  TZ = 'Tansania',
  UA = 'Ukraine',
  UG = 'Uganda',
  UM = 'Amerikanische Überseeinseln',
  US = 'Vereinigte Staaten',
  UY = 'Uruguay',
  UZ = 'Usbekistan',
  VA = 'Vatikanstadt',
  VC = 'St. Vincent und die Grenadinen',
  VE = 'Venezuela',
  VG = 'Britische Jungferninseln',
  VI = 'Amerikanische Jungferninseln',
  VN = 'Vietnam',
  VU = 'Vanuatu',
  WF = 'Wallis und Futuna',
  WS = 'Samoa',
  YE = 'Jemen',
  YT = 'Mayotte',
  ZA = 'Südafrika',
  ZM = 'Sambia',
  ZW = 'Simbabwe',
}
